import * as React from 'react';
import { useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import {
    useRecordContext,
    useListContext,
    useCreate,
    useUpdate,
    useNotify,
    useGetIdentity,
    Identifier,
    useResourceContext,
    FileInput, FileField,
    Form,
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
    CreateBase,
    SaveButton,
    SimpleForm,
    useCreateContext,
    Toolbar,
    TextInput,
    SelectInput,
    required, 
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    useDataProvider,
    useRedirect,
    useRefresh,
    NumberInput,
    DateInput,
} from 'react-admin';
import { Box, TextField, Button, Grid, Typography  } from '@mui/material';
import { StatusSelector } from '../misc/StatusSelector';
import {useFormContext} from "react-hook-form";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export const AccountingFillForm = () => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const { refetch } = useListContext();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const ref = useRef();

    if (!identity) return null;

    return (
        <Box>
            <CreateBase resource="accounting"
                redirect={false}
                mutationMode="optimistic"
                transform={(data) => {
                    return ({
                        ...data,
                        ap_id: localStorage.getItem('id'),
                        ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                    });
                }}
                mutationOptions={{
                    onSuccess: () => {
                        try{
                           //refetch();
                           notify('Информация о финансах сделки обновлена', { type: 'info', undoable: false });
                           ref.current.resetForm();
                        }catch(err){console.log(err)}
                    }}
                }
            > 
            <Form>
            <AccountingForm 
                ref={ref}
            />
            </Form>
            </CreateBase>
        </Box>
    );
};

const AccountingForm = forwardRef(({ setSelectedDeal },  ref) => {
    const { reset, setValue, watch } = useFormContext();

    useImperativeHandle(ref, () => ({
        resetForm() {
            // Reset specific form fields
           // setValue('comment', '');
           // setValue('file', '');
           // setValue('date_time', '');
        }
    })); 

        return (
            <Box mb={3}>
                <h3>Меню бухгалтера (внесение/изменение информации)</h3>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="deal_id" reference="deals" >
                            <AutocompleteInput isLoading={true} label="Сделка (введите часть имени клиента или номера сделки)" filterToQuery={search => ({ "q": search })}  optionText={(choice) => `Сделка №${choice.document_name} ${choice.name} - ${choice.manager_name}`} fullWidth validate={required()} />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="manager_account_id" reference="sales">
                            <AutocompleteInput label="Менеджер" filterToQuery={search => ({ "q": search })} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} fullWidth validate={required()} />
                        </ReferenceInput>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <NumberInput label="Плановое поступление, руб." source="income_plan" fullWidth validate={required()}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <NumberInput label="Фактически поступило, руб." source="income_fact" fullWidth validate={required()}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DateInput label="Дата" source="date" validate={required()} />
                    </Grid>
                </Grid>
                <Box display="flex" mb={1} >
                    <SaveButton 
                        label="Внести информацию"
                        icon={false}
                        redirect={false} 
                        submitOnEnter={false}
                    />
                </Box>
                <div>* При повторном внесении информации в ту же дату по сделке и менеджеру - она перезаписывается полностью, учитывайте это.</div>     
            </Box>
        );

    return null;
});
import React, { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useRedirect,
    useDataProvider,
    DateTimeInput,
    useGetList,
    CreateBase, Form, Toolbar,
    useNotify,
    Loading,
} from 'react-admin';
import { endpoint, wsEndpoint } from '../App';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
    Icon
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useSound } from 'use-sound';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';

const getIconByTalkTime = (talkTime) => {
    if(talkTime > 0){
        return <PhoneForwardedIcon style={{ color: 'green' }} />;
    } 
      
    return <PhoneDisabledIcon style={{ color: 'red' }} />;
};

export const CallsWithContact = ({contact_id}) => {  
const [calls, setCalls] = useState([]);
const [loading, setLoading] = useState(true);

useEffect(() => {
    fetchData();
}, []);

const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // Get token from localStorage
      //const id = localStorage.getItem('id'); // Get id from localStorage
      const filterParams = {
        //manager_account_id: id,
        contact_id: contact_id,
      }
      const response = await axios.get(
        `${endpoint}/calls?filter=${encodeURIComponent(JSON.stringify(filterParams))}&sort=${encodeURIComponent(JSON.stringify(["EventTime", "DESC"]))}`, //create_time for Mango // EventTime for Telphin
        {
          headers: {
            Authorization: `${token}` // Set Authorization header with token
          },
        }
      );
      // Check if the response data is different from the current notifications
      console.log(new Date().toISOString() + " " + JSON.stringify(response.data));
      setCalls(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Fetch failed:', error);
    }
};

  // Mango Calls List Show
  /*return (
    <>
    {loading ? <Loading /> : (
    calls.length > 0 ? (
        calls.map(call => (
          <Box key={call.id} style={{
            border: '1px solid #ccc',
            borderRadius: '1rem',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '0.2em'
          }}>
            <Icon sx={{marginRight: '1rem'}}>{getIconByTalkTime(call.talk_time)}</Icon>
            <div style={{paddingRight: '1rem'}}>От: {call.manager_name}</div>
            {call.record_link && (
                <audio controls style={{width: '70%', marginRight: '1rem'}}>
                    <source src={call.record_link} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            )}
            <div style={{paddingRight: '1rem'}}>Время звонка: {new Date(call.create_time * 1000).toLocaleString()}</div>
          </Box>
        ))
      
    ) : <Typography variant="body1" style={{marginLeft: '1rem', marginTop: '1rem'}}>Звонков пока не совершено</Typography>
    )}
    </>
  );*/

  // Telphin Calls List Show
  return (
    <>
    {loading ? <Loading /> : (
    calls.length > 0 ? (
        calls.map(call => (
          <Box key={call.id} style={{
            border: '1px solid #ccc',
            borderRadius: '1rem',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '0.2em'
          }}>
            <Icon sx={{marginRight: '1rem'}}>{getIconByTalkTime(call.Duration)}</Icon>
            <div style={{paddingRight: '1rem'}}>От: {call.manager_name}</div>
            {call.record_link && (
                <audio controls style={{width: '70%', marginRight: '1rem'}}>
                    <source src={call.record_link} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            )}
            <div style={{paddingRight: '1rem'}}>Время звонка: {new Date(call.EventTime / 1000).toLocaleString()}</div>
          </Box>
        ))
      
    ) : <Typography variant="body1" style={{marginLeft: '1rem', marginTop: '1rem'}}>Звонков пока не совершено</Typography>
    )}
    </>
  );

};
import React, { useState, useEffect } from 'react';
import { endpoint, wsEndpoint } from '../App';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SimpleList,
    FilterLiveSearch,
    FunctionField,
    Loading,
    DateInput,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    Form
} from 'react-admin';
import {
    Box, 
    useMediaQuery,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from 'axios';
import { AccountingFillForm } from './AccountingFillForm';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: accounts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const filteredDataToExport = accounts.map(({ avatar, password, connectedToLeads, picture, ...rest }) => rest);

    const ws = XLSX.utils.json_to_sheet(filteredDataToExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Аналитика - Выгрузка из CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

const formatNumberWithSpaces = (number) => {
    return new Intl.NumberFormat('en-US', { 
        minimumFractionDigits: 0, 
        maximumFractionDigits: 0 
    }).format(number).replace(/,/g, ' ');
};

const AccountingFilter = ({ setFilters, defaultStartDate, defaultEndDate, currentManagerFilter, handleSelectedManagerFilterChange }) => {
    const handleApplyFilters = (values) => {
        if (values.startDate && values.endDate) {
            setFilters({ search_start_time: values.startDate, search_end_time: values.endDate });
        }
    };

    return (
        <Form className='filterForm' onSubmit={handleApplyFilters}>
            <Box display="flex" flexDirection="row" mb={2} ml={2} mt={2} alignItems="center">
            <DateInput
                    label="Начало периода"
                    source="startDate"
                    defaultValue={defaultStartDate}
                    style={{ marginRight: '1rem' }}
                />
                <DateInput
                    label="Окончание периода"
                    source="endDate"
                    defaultValue={defaultEndDate}
                    style={{ marginRight: '1rem' }}
                />
                    {/*localStorage.getItem('role_id') <= 3 &&
                    <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'head_of_dep': true }} >
                        <AutocompleteInput 
                            label="Руководитель" 
                            defaultValue={currentManagerFilter} 
                            onChange={handleSelectedManagerFilterChange} 
                            optionText={(choice) => `${choice.first_name} ${choice.last_name}`} 
                        />
                    </ReferenceInput>*/}
                    <Button type="submit" variant="contained" color="primary" style={{ marginLeft: '1rem' }}>
                        Показать
                    </Button>
            </Box>
        </Form>
    );
};

function sortDealsByPaymentDate(deals) {
    const today = new Date();

    // Split deals into those with future payments and those without
    const dealsWithFuturePayments = [];
    const dealsWithoutFuturePayments = [];

    deals.forEach((deal) => {
        const payments = Array.isArray(deal.payments) ? deal.payments : []; // Ensure payments is an array

        const futurePayments = payments.filter(payment => new Date(payment.date) > today);

        if (futurePayments.length > 0) {
            // Add deal to deals with future payments, along with the next payment date
            dealsWithFuturePayments.push({
                ...deal,
                nextPaymentDate: new Date(Math.min(...futurePayments.map(payment => new Date(payment.date))))
            });
        } else {
            // Add deal to deals without future payments
            dealsWithoutFuturePayments.push(deal);
        }
    });

    // Sort deals with future payments by the next payment date (ascending)
    dealsWithFuturePayments.sort((a, b) => a.nextPaymentDate - b.nextPaymentDate);

    // Sort deals without future payments by the last payment date (descending)
    dealsWithoutFuturePayments.sort((a, b) => {
        const lastPaymentA = new Date(Math.max(...(Array.isArray(a.payments) ? a.payments : []).map(payment => new Date(payment.date))));
        const lastPaymentB = new Date(Math.max(...(Array.isArray(b.payments) ? b.payments : []).map(payment => new Date(payment.date))));
        return lastPaymentB - lastPaymentA;
    });

    // Combine the two sorted lists
    return [...dealsWithFuturePayments, ...dealsWithoutFuturePayments];
}

const DebitoryPage = () => {
    const { identity } = useGetIdentity();
    const navigate = useNavigate();
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [numDays, setNumDays] = useState(1);
    const [filters, setFilters] = useState({
        search_start_time: moment.utc().startOf('month').toDate(),
        search_end_time: moment.utc().endOf('month').toDate(),
    });
    const [currentManagerFilter, setCurrentManagerFilter] = useState(null);

    useEffect(() => {
        fetchData(filters);
    }, []);

    const fetchData = async (filters) => {
        setLoading(true);

        setNumDays(Math.ceil((new Date(filters.search_end_time) - new Date(filters.search_start_time)) / (1000 * 60 * 60 * 24)) + 1);

        try {
            const token = localStorage.getItem('token');
            const id = localStorage.getItem('id');
            const response = await axios.get(
                `${endpoint}/debitory`,
                {
                    params: {
                        manager_account_id: id,
                        team_members_head_id: currentManagerFilter,
                        ...filters
                    },
                    headers: {
                        Authorization: `${token}`
                    }
                }
            );

            const sortedDeals = sortDealsByPaymentDate(response.data);
            
            if(localStorage.getItem('role_id') > 3){
                // Менеджеры видят только себя
                const filteredDeals =  sortedDeals.filter(deal => deal.manager_account_id === id);
                setDeals(filteredDeals);
            }else{
                // Остальные видят всё

                setDeals(sortedDeals);
            }
        } catch (error) {
            console.error('Fetch failed:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSetFilters = (filters) => {
        fetchData(filters);
    };

    const handleSelectedManagerFilterChange = (value) => {
        setCurrentManagerFilter(value);
    };

    // Calculate totals
    const totalDealPrice = deals.reduce((acc, deal) => acc + deal.deal_price, 0);
    const totalPaid = deals.reduce((acc, deal) => acc + deal.total_fact, 0);
    const totalRemaining = totalDealPrice - totalPaid;
    const totalDebt = deals.reduce((acc, deal) => {
        const totalPaymentsDue = deal.payments?.filter(payment => new Date(payment.date) <= new Date())
            .reduce((sum, payment) => sum + payment.amount, 0) || 0;
        return acc + Math.max(totalPaymentsDue - deal.total_fact, 0);
    }, 0);


    return identity ? (
        <Box>
            {localStorage.getItem('accountant') == 'true' && <AccountingFillForm />}
            <Box display="flex" flexDirection="row" alignItems="start">
            <h3>Дебиторская задолженность</h3>
            <AccountingFilter 
                setFilters={handleSetFilters} 
                defaultStartDate={filters.search_start_time} 
                defaultEndDate={filters.search_end_time}
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
            />
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <List sx={{ backgroundColor: 'background.paper' }}>
                    <ListItem sx={{ padding: 0, alignItems: 'stretch', borderBottom: '1px solid lightgrey' }}>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="ФИО клиента" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Менеджер" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Сумма покупки (₽)" secondary={`общая цена сделки`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Оплачено (₽)" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Остаток (₽)" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Долг (₽)" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Последний платёж" secondary={`по факту`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Следующий платёж" secondary={`по графику`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                    </ListItem>
            {deals.map(deal => {

                    // Get today's date
                    const today = new Date();

                    // Check if payments exist and are an array
                    const nearestFuturePayment = Array.isArray(deal.payments)
                        ? deal.payments
                            .filter(payment => new Date(payment.date) > today) // Filter for future dates
                            .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date ascending
                            [0] // Get the first element (nearest future date)
                        : null;

                    // Filter and sum the payments that are due (past due dates)
                    const totalPaymentsDue = deal.payments
                        ? deal.payments
                            .filter(payment => new Date(payment.date) <= today) // Only payments with past due dates
                            .reduce((acc, payment) => acc + payment.amount, 0) // Sum the amounts
                        : 0;

                    // Calculate debt
                    const debt = totalPaymentsDue > deal.total_fact ? totalPaymentsDue -  deal.total_fact : 0;

                    // Find the last payment date with income_fact > 0
                    const lastPaymentDate = deal.accounting
                    .filter(entry => entry.income_fact > 0) // Filter entries with income_fact > 0
                    .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date descending
                    .map(entry => entry.date)[0] || null; 

                    return (
                        <ListItem key={deal.id} sx={{ padding: 0, alignItems: 'stretch', cursor: 'pointer' }} onClick={() => navigate(`/deals/${deal.id}`)} >
                            {/*  ФИО клиента */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${deal.document_name} - ${deal.name}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight:'normal' }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {/* Менеджер */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${deal.manager_name}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight:'normal' }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {/*  Сумма покупки, ₽ */}       
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${formatNumberWithSpaces(deal.deal_price)}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>

                            </Box>

                            {/*  Оплачено, ₽ */}       
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${formatNumberWithSpaces(deal.total_fact)}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>

                            </Box>

                            {/*  Остаток, ₽ */}              
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${formatNumberWithSpaces(deal.deal_price - deal.total_fact)}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>

                            </Box>

                            {/*  Долг */}                  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${formatNumberWithSpaces(debt)}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>

                            </Box>

                            {/* Последний платёж */}
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${lastPaymentDate ? formatDateString(lastPaymentDate) : 'Оплат не было'}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {/*  Следующий платеж */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey'  
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${nearestFuturePayment ? formatDateString(nearestFuturePayment.date) : 'Не запланировано'}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>

                            </Box>

                        </ListItem>
                    );
                })}
                    <ListItem sx={{ padding: 0, alignItems: 'stretch', borderBottom: '1px solid lightgrey' }}>
                        <Box display="flex" flex={2} justifyContent="start" alignItems="center" sx={{ minWidth: '14rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Итого" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary={`${formatNumberWithSpaces(totalDealPrice)}`} secondary={`Сумма покупки (₽)`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary={`${formatNumberWithSpaces(totalPaid)}`} secondary={`Оплачено (₽)`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary={`${formatNumberWithSpaces(totalRemaining)}`} secondary={`Остаток (₽)`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary={`${formatNumberWithSpaces(totalDebt)}`} secondary={`Долг (₽)`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Последний платёж" secondary={`по факту`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Следующий платёж" secondary={`по графику`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                    </ListItem>
                </List>
            )}
        </Box>
    ) : (
        <Loading />
    );
};

const ListActions = () => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>

    </TopToolbar>
);

export default DebitoryPage;

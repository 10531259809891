import * as React from 'react';
import { useState, FormEvent, ChangeEvent } from 'react';
import {
    TextField,
    ReferenceField,
    DateField,
    useResourceContext,
    useDelete,
    useUpdate,
    useCreate,
    useNotify,
    useRedirect,
    useRecordContext,
    useListContext,
    useRefresh,
    DateTimeInput,
    required,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    Form,
    TextInput,
    useDataProvider
} from 'react-admin';
import {
    Box,
    Typography,
    Tooltip,
    IconButton,
    FilledInput,
    Button,
    Link,
    Grid,
    Paper
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Status } from '../misc/Status';
import { endpoint } from '../App';

export const HistoryComment = ({
    note,
    reference,
}) => {
    const [isHover, setHover] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [isEditingTask, setEditingTask] = useState(false);
    const [noteText, setNoteText] = useState(note.comment);
    const [noteDateTime, setNoteDateTime] = useState(note.date_time);
    const [noteManagerAccountId, setNoteManagerAccountId] = useState(note.task_manager_id);
    const [noteManagerName, setNoteManagerName] = useState(note.manager_name);
    const [noteTaskType, setNoteTaskType] = useState(note.task_type);
    const [taskAnswer, setTaskAnswer] = useState(note.task_answer);
    const { refetch } = useListContext();
    const redirect = useRedirect();
    const resource = useResourceContext();
    const record = useRecordContext();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const [update, { isLoading }] = useUpdate();

    const [deleteNote] = useCreate(
        'delete',
        { id: note.id, 
          data:{ id: note.id, table: resource, 
          comment: note.comment,
          comment_type: note.comment_type,
          file_name: note.file_name, 
          file_path: note.file_path,
          request_id: record.id, 
          request_number: record.number,
          ap_id: localStorage.getItem('id'),
          ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
          task_manager_id: note.task_manager_id,
          contact_name: note.contact_name ? note.contact_name : "",
          },
          previousData: note
        },
        {
            mutationMode: 'pessimistic',
            onSuccess: () => {
                notify(note.comment_type == "task" ? 'Задача удалена' : 'Комментарий удален', { type: 'info', undoable: false });
                refetch();
                //window.location.reload();
            },
        }
    );

    const handleDelete = () => {
        deleteNote();
    };

    const handleEnterEditMode = () => {
        setEditing(true);
    };

    const handleCancelEdit = () => {
        setEditing(false);
        setNoteText(note.comment);
        setHover(false);
    };

    const handleEnterEditTaskMode = () => {
        setEditingTask(true);
    };

    const handleCancelTaskEdit = () => {
        setEditingTask(false);
        setHover(false);
    };

    const handleTextChange = (event) => {
        setNoteText(event.target.value);
    };
    const handleAnswerChange = (event) => {
        setTaskAnswer(event.target.value);
    };

    const handleDateTimeChange = (event) => {
        setNoteDateTime(event.target.value);
    };
    const handleManagerChange = async(event) => {
        var id = event.target.value;
        var recordFromDatabase = await dataProvider.getOne('sales', { id });
        setNoteManagerAccountId(id);
		setNoteManagerName(recordFromDatabase.data.first_name + " " + recordFromDatabase.data.last_name );
    };
    const handleTaskTypeChange = (event) => {
        setNoteTaskType(event.target.value);
    };

    const handleNoteUpdate = (event) => {
        event.preventDefault();
        update(
            resource,
            { id: note.id, 
              data: { id: note.id, created_at: note.created_at, request_id: reference === "requests" ? record.id : record.request_id, file_name: note.file_name, file_path: note.file_path,
          request_number: reference === "requests" ? record.number : record.request_number, comment: noteText, comment_type: note.comment_type, ap_id: localStorage.getItem('id'), ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'), }, previousData: note },
            {
                onSuccess: () => {
                    setEditing(false);
                    setNoteText(note.comment);
                    setHover(false);
                    refetch();
                },
            }
        );
    };
    const handleTaskUpdate = () => {
        update(
            resource,
            { id: note.id, 
              data: { id: note.id, created_at: note.created_at, request_id: reference === "requests" ? record.id : record.request_id,file_name: note.file_name, file_path: note.file_path,
          request_number: reference === "requests" ? record.number : record.request_number, comment: noteText, comment_type: note.comment_type, task_done: false, ap_id: localStorage.getItem('id'), ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'), 
          date_time: noteDateTime, task_manager_id: noteManagerAccountId, manager_name: noteManagerName, task_type: noteTaskType}, previousData: note },
            {
                onSuccess: () => {
                    setEditingTask(false);
                    setHover(false);
                    refetch();
                    //window.location.reload();
                },
            }
        );
    };
    const handleAddTaskAnswer = (event) => {
        event.preventDefault();
        update(
            resource,
            { id: note.id, 
              data: { id: note.id, created_at: note.created_at, request_id: reference === "requests" ? record.id : record.request_id, file_name: note.file_name, file_path: note.file_path,
          request_number: reference === "requests" ? record.number : record.request_number, comment: noteText, comment_type: note.comment_type, task_answer: taskAnswer, task_done: true, ap_id: localStorage.getItem('id'), ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'), }, previousData: note },
            {
                onSuccess: () => {
                    setEditing(false);
                    setNoteText(note.comment);
                    setHover(false);
                    refetch();
                },
            }
        );
    };

    const getBorderColor = () => {
        const currentTime = new Date();
        const taskTime = new Date(note.date_time);
    
        if (note.task_done) {
            return '#aaa'; // Gray - Task is done
        } else if (taskTime < currentTime) {
            return 'red'; // Red - Task is overdue
        } /*else if (taskTime >= currentTime && taskTime < new Date(currentTime.getTime() + 24 * 60 * 60 * 1000)) {
            return 'green'; // Orange - Today's tasks
        } */else {
            return 'green'; // Blue - Task is future (tomorrow or after)
        }
    };

    const getEditLabel = () => {
        if(note.comment_type == "task"){
            if(note.task_answer || note.task_done){
                return 'Редактировать ответ на задачу';
            }else{
                return 'Завершить задачу';
            }
        }else{
            return 'Редактировать комментарий';
        }
    };

    const getEditSubmitButtonLabel = () => {
        if(note.comment_type == "task"){
            if(note.task_done){
                return 'Обновить ответ';
            }else{
                return 'Завершить задачу';
            }
        }else{
            return 'Обновить комментарий';
        }
    };

    return (
        <Paper
            mb={2}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{ padding: '0.3rem', marginTop: '0.3rem', border: note.comment_type == "task" ? `2px solid ${getBorderColor()}` : 'null', }}
        >
            <Box mb={1} color="text.secondary" flex="1" display="flex" flexDirection="row" alignItems="center">
                {note.comment_type == "task" ?  <Typography variant="body2">Задача:{note.task_type}, </Typography> : null}
                {note.comment_type == "message" ?  <Typography variant="body2">Отправлено сообщение клиенту, </Typography> : null}
                {note.ap_id ? 
                <ReferenceField
                    record={note}
                    resource="requestsHistory"
                    source="ap_id"
                    reference="sales"
                    link={(record, reference) => `/${reference}/${record.id}/show`}
                >
                {' '}<TextField source="first_name" ml={1}/>{' '}
                <TextField source="last_name" />
                </ReferenceField> : 
                <Typography variant="body2" ml={1}>Система</Typography>}
                {', '} 
                <DateField
                    source="created_at"
                    record={note}
                    variant="body2"
                    showTime
                    locales="ru"
                    options={{
                        dateStyle: 'full',
                        timeStyle: 'short',
                    }}
                    ml={1}
                />
            </Box>
            {note.comment_type == "task" ?  
                <Box flex="1" display="flex" flexDirection="row" alignItems="center">
                <Typography variant="body2">{note.task_done ? 'Время выполнения:' : 'Выполнить до:'} {note.task_done ? new Date(note.updated_at).toLocaleString('ru-RU') : new Date(note.date_time).toLocaleString('ru-RU')}, </Typography> 
                    <Typography variant="body2">Исполнитель: </Typography> 
                     <ReferenceField
                    record={note}
                    resource="requestsHistory"
                    source="task_manager_id"
                    reference="sales"
                    link={(record, reference) => `/${reference}/${record.id}/show`}
                >
                {' '}<TextField source="first_name" />{' '}
                <TextField source="last_name" />
                </ReferenceField>{', '}
                </Box>
                : null}
            {isEditing ? (
                <form onSubmit={note.comment_type == "task" ? handleAddTaskAnswer : handleNoteUpdate}>
                    { note.comment && note.comment !== 'undefined' ? 
                    <Box flex={1}>
                        {note.comment
                            .split('\n')
                            .map((paragraph, index) => (
                                <Box
                                    component="p"
                                    fontFamily="fontFamily"
                                    fontSize="body1.fontSize"
                                    lineHeight={1.3}
                                    marginBottom={2.4}
                                    key={index}
                                    style={{ textDecoration: note.task_answer ? 'line-through' : 'none' }}
                                >
                                    {paragraph}
                                </Box>
                            ))}
                    </Box> : null }
                    { note.task_answer ? 
                    <Box flex={1}>
                    {note.task_answer
                            .split('\n')
                            .map((paragraph, index) => (
                                <Box
                                    component="p"
                                    fontFamily="fontFamily"
                                    fontSize="body1.fontSize"
                                    lineHeight={1.3}
                                    marginBottom={2.4}
                                    key={index}
                                >
                                    {paragraph}
                                </Box>
                            ))}
                    </Box> : null }
                    <FilledInput
                        value={note.comment_type == "task" ? taskAnswer : noteText}
                        onChange={note.comment_type == "task" ? handleAnswerChange : handleTextChange}
                        fullWidth
                        multiline
                        placeholder={note.comment_type == "task" ? "Ответ на задачу (если требуется)" : ""}
                        sx={{
                            paddingTop: '16px',
                            paddingLeft: '14px',
                            paddingRight: '60px',
                            paddingBottom: '14px',
                            lineHeight: 1.3,
                        }}
                        autoFocus
                    />
                    <Box display="flex" justifyContent="flex-end" mt={1}>
                        <Button
                            sx={{ mr: 1 }}
                            onClick={handleCancelEdit}
                            color="primary"
                        >
                            Отмена
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isLoading}
                        >
                            {getEditSubmitButtonLabel()}
                        </Button>
                    </Box>
                </form>
            ) : null}
            {isEditingTask ? (
                <Form onSubmit={handleTaskUpdate}>
                    <Box>
                    <TextInput
                        label={"Введите текст задачи"}
                        fullWidth
                        multiline
                        source="comment"
                        rows={2}
                        defaultValue={noteText}
                        onChange={handleTextChange}
                        validate={required()}
                    />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                    <Box flex="1" flexDirection="column">
                                        <DateTimeInput label="Назначить на" source="date_time" defaultValue={noteDateTime} validate={required()} onChange={handleDateTimeChange} />
                                        <ReferenceInput source="task_manager_id" reference="sales" filter={{ 'sales_id_for_tasks': localStorage.getItem('id') }}>
                                            <SelectInput label="Менеджер" defaultValue={noteManagerAccountId}  onChange={handleManagerChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} fullWidth validate={required()} />
                                        </ReferenceInput>
                                    </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box flex="1" flexDirection="column">
                                        <ReferenceInput source="task_type" reference="taskTypes" >
                                            <SelectInput label="Тип задачи" optionText="name" optionValue="name" defaultValue={noteTaskType} onChange={handleTaskTypeChange} fullWidth validate={required()} />
                                        </ReferenceInput> 
            
                                </Box>
                            </Grid>
                        </Grid>  
                    </Box>
                    <Box display="flex" justifyContent="flex-end" mt={1}>
                        <Button
                            sx={{ mr: 1 }}
                            onClick={handleCancelTaskEdit}
                            color="primary"
                        >
                            Отмена
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isLoading}
                        >
                            Обновить задачу
                        </Button>
                    </Box>
                </Form>
            ) : null}
            {!isEditing && !isEditingTask ? (
                <Box
                    sx={{
                        padding: '0 1em',
                        display: 'flex',
                        alignItems: 'stretch',
                    }}
                >
                <Box flex={1} flexDirection="column">
                { note.comment && note.comment !== 'undefined' ? 
                    <Box flex={1}>
                        {note.comment
                            .split('\n')
                            .map((paragraph, index) => (
                                <Box
                                    component="p"
                                    fontFamily="fontFamily"
                                    fontSize="body2.fontSize"
                                    lineHeight={1.3}
                                    key={index}
                                    style={{ 
                                        textDecoration: note.task_done ? 'line-through' : 'none',
                                        fontWeight: note.comment_type !== 'system_info' && note.comment_type !== 'message' ? 'bold' : 'normal',
                                        wordBreak: 'break-all',
                                     }}
                                >
                                    {paragraph}
                                </Box>
                            ))}
                    </Box> : null }
                    { note.task_answer ? 
                    <Box flex={1}>
                    {note.task_answer
                            .split('\n')
                            .map((paragraph, index) => (
                                <Box
                                    component="p"
                                    fontFamily="fontFamily"
                                    fontSize="body1.fontSize"
                                    lineHeight={1.3}
                                    key={index}
                                    style={{ 
                                        fontWeight: note.comment_type !== 'system_info' ? 'bold' : 'normal'
                                    }}
                                >
                                    {paragraph}
                                </Box>
                            ))}
                    </Box> : null }
                    { note.file_name ? 
                        <Box flex={1} mt={1}>
                            <Link href={`${endpoint}/${note.file_path}`} target="_blank" rel="noopener">{note.file_name}</Link>
                        </Box> : null }
                    </Box>
                    <Box
                        sx={{
                            marginLeft: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            visibility: isHover ? 'visible' : 'hidden',
                        }}
                    >
                        { note.comment_type === "task" && !note.task_done && (note.ap_id === localStorage.getItem('id') || note.task_manager_id === localStorage.getItem('id')) && note.comment !== 'undefined' ? <Tooltip title={getEditLabel()}>
                            <IconButton
                                size="small"
                                onClick={handleEnterEditMode}
                            >
                                { note.comment_type !== "task" ? <EditIcon /> : <TaskAltIcon />}
                            </IconButton>
                        </Tooltip> : null}
                        { note.comment_type === "task" && !note.task_done && (note.ap_id === localStorage.getItem('id') || note.task_manager_id === localStorage.getItem('id')) && note.comment !== 'undefined' ? <Tooltip title={"Изменить задачу"}>
                            <IconButton
                                size="small"
                                onClick={handleEnterEditTaskMode}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip> : null}
                        { (note.comment_type === "task" || (localStorage.getItem('role_id') < 2 && note.comment_type !== "message")) && /*!note.task_answer && note.ap_id === localStorage.getItem('id')?*/ <Tooltip title={note.comment_type == "task" ? "Удалить задачу" : "Удалить комментарий"} >
                            <IconButton size="small" onClick={handleDelete}>
                                <TrashIcon />
                            </IconButton>
                        </Tooltip>/* : null*/}
                    </Box>
                </Box>
            ) : null}
        </Paper>
    );
};
